import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import FormSections from "../components/reusableUi/FormSections"
import TextBlock from "../components/reusableUi/TextBlock"
import Img from "gatsby-image"
import Slider from "../components/slider/Slider"
import Wc from "../components/reusableUi/Wc"
import Interior from "../components/reusableUi/InteriorChoices"
import Connecter from "../components/reusableUi/Connected"
import icon from "../images/svg/ca-icon.svg"

const Radhus = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Radhus"
        description="Kalcium består av tre byggnader som med ett ljust tegel, guldfärgsmålade fönsterkarmar och silverlackade plåtdetaljer erbjuder ett personligt och smakfullt boende. Uppdelningen i tre radhus på två våningar och tre flerfamiljshus på fyra, fem och sex våningar (hus A, B och C) skapar en variation som känns inbjudande och på ett naturligt sätt smälter in i omgivningarna."
      />
      <Hero
        iconImage={icon}
        heroClass="default-hero"
        heroImage={data.heroBackground.childImageSharp.fluid}
        hideQuick={"lagenheter"}
      />
      <TextBlock
        image={data.exteriorImage.childImageSharp.fluid}
        text={data.utsikter}
        theClass="fixedBG"
      />
      <Slider text={data.interiortSliderText}>
        <div>
          <Img fluid={data.vardagsrum.childImageSharp.fluid} />
        </div>
        <div>
          <Img fluid={data.kok.childImageSharp.fluid} />
        </div>
        <div>
          <Img fluid={data.badrum.childImageSharp.fluid} />
        </div>
      </Slider>
      <Wc />
      <Interior />
      <Connecter />
      <FormSections />
    </Layout>
  )
}

export default Radhus

export const pageQuery = graphql`
  query {
    utsikter: mdx(
      frontmatter: { title: { eq: "Inbjudande miljö med strålande utsikter" } }
    ) {
      frontmatter {
        title
      }
      body
    }

    heroBackground: file(relativePath: { eq: "exterior/Exterior3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    exteriorImage: file(relativePath: { eq: "exterior/Exterior4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    interiortSliderText: mdx(
      frontmatter: { title: { eq: "radhusSlider" } }
    ) {
      frontmatter {
        title
      }
      body
    }

    vardagsrum: file(relativePath: { eq: "interior/Vardagsrum.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    kok: file(relativePath: { eq: "interior/Kok.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    badrum: file(relativePath: { eq: "interior/Badrum.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
